<template>
  <div
    id="global-search"
    class="tw-flex"
    style="width: 400px"
  >
    <feather-icon
      icon="SearchIcon"
      class="icon--search tw-text-text-primary"
    />
    <v-select
      :data-hide-options="$lodash.get(searchInput, 'length', 0) < 3 || loading"
      :options="options"
      :loading="loading"
      :placeholder="$t('Search')"
      :value="searchInput"
      @search="handleSearch"
      @input="handleInput"
      @option:selecting="handleSelect"
    >
      <template #selected-option-container="{ option }">
        <div class="vs__selected">{{ option.label }}</div>
      </template>
      <template slot="no-options">
        {{ $t('Sorry, no matching options') }}
      </template>
      <template #option="option">
        <div class="tw-px-1 d-flex flex-column">
          <strong>{{ option.label }}</strong>
          <small>{{ $t($case.title(option.tableKey)) }}</small>
          <small v-if="supportingData(option, 1)">
            {{ supportingData(option, 1) }}
          </small>
          <small v-if="supportingData(option, 2)">
            {{ supportingData(option, 2) }}
          </small>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import VSelect from 'vue-select'
import simpleSearch from '@/services/simpleSearch'
import { formatRouterLink } from '@/helpers/customEntity'

export default {
  components: {
    VSelect,
  },

  data() {
    return {
      loading: false,
      searchInput: '',
      options: [],
      globalSearchTimeout: null,
    }
  },

  methods: {
    clear() {
      this.searchInput = ''
      this.options = []
    },

    handleInput(input) {
      if (!input) {
        this.options = []
      }
    },

    handleSearch(input) {
      this.searchInput = input

      this.loading = this.searchInput?.length >= 3

      this.doDebounce(
        'globalSearchTimeout',
        async () => {
          if (input?.length < 3) {
            this.loading = false
            return
          }
          const { response } = await this.$async(simpleSearch.search({ q: input, size: 10 }))

          this.options = response.data.map(datum => {
            const [schemaName, tableKey] = datum.entity.split('.')
            const data = {
              label: datum.name,
              key: datum.uuidKey,
              schemaName,
              tableKey,
              ...datum,
            }
            switch (data.supportingLabel1) {
              case 'Mobile Number':
                data.supportingData1 = data.supportingData1.replaceAll(' ', '')
                data.label = `${data.label} ${data.supportingData1}`
              break
              default: break
            }
            data.label = `${data.label} ${data.label.replaceAll(' ', '')}`
            data.name = `${data.name} ${data.name.replaceAll(' ', '')}`
            return data
          })
          this.loading = false
        },
        800,
      )
    },

    handleSelect(selection) {
      this.clear()
      this.$router.push(formatRouterLink(selection))
    },

    supportingData(option, number) {
      const label = option[`supportingLabel${number}`]
      const data = option[`supportingData${number}`]

      return data ? `${label}: ${data}` : null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
@import '@/assets/scss/variables/variables';
@import '@/assets/scss/mixins.scss';

#global-search {
  .icon--search {
    position: relative;
    top: 8px;
  }

  .v-select {
    margin-left: 10px;
    width: 175px;

    @include sm {
      width: 250px;
    }

    @include md {
      width: 320px;
    }
  }

  .vs__selected-options {
    input {
      padding-left: 0;

      &:focus {
        padding-bottom: 3px;
        border-bottom: 1px solid var(--colour--input-border);
      }
    }
  }

  .vs__dropdown-menu {
    margin: 0 !important;
    position: relative;
    top: 2px;
  }

  .vs__dropdown-toggle {
    border-radius: 0.357rem !important;
    border: none;
    padding-left: 0;
  }

  .vs__open-indicator {
    display: none !important;
  }

  .vs__spinner {
    border-color: gray;
    border-left-color: white;
    border-width: 1.5px;
    width: 18px;
    height: 18px;
  }

  [data-hide-options='true'] ul[role='listbox'] {
    display: none !important;
  }

  .vs__dropdown-option--highlight {
    color: $white !important;
    background-color: var(--colour--primary) !important;
  }

  ul[role='listbox'] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>
